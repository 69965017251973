import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import React, {
  ChangeEventHandler,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import UIContext from "../../../../contexts/uiContext/UIContext";
import SendIcon from "@mui/icons-material/Send";
import DownloadButton from "../../../shared/downloadbtn/Download";
import useQuerySearch from "../../../hooks/querySearch/useQuerySearch";
import useGetCustomerEnquiryDetails from "../../../hooks/customerEnquiryDetails/useGetCustomerEnquiryDetails";
import useGetEnquiryIframeDetails from "../../../hooks/enquiryIframeDetails/useGetEnquiryIframeDetails";
import { IPagination } from "../../../../@types/interface/pagination";
import { api } from "../../../../utils/api";
import { ROLES } from "../../../../constants/roles/Roles";
import AuthContext from "../../../../contexts/authContext/authContext";
import DataGrid from "../../../shared/dataGrid/DataGrid";
import { enquiryColumnDef } from "../../../../constants/enquiry/EnquiryColDef";
import PermissionContext from "../../../../contexts/permissionContext/permissionContext";
import NoAccess from "../../../shared/noAccess/NoAccess";

const LeadReport = () => {
  const { setDashboardHeader } = useContext(UIContext);
  const [selectedLeadSource, setSelectedLeadSource] = useState<string>("");
  const { user } = useContext(AuthContext);
  const {permissionDetails} = useContext(PermissionContext)
  const [alternativeReportName, setAlternativeReportName] =
    useState<string>("");
  const [filters, setFilters] = useState([]);
  const [pagination, setPagination] = useState<IPagination>({
    currentPage: 1,
    pageCount: 1,
  });
  const [incornersLeadDetails, setIncornersLeadDetails] = useState<any>([]);
  const [websiteLeadDetails, setWebsiteLeadDetails] = useState<any>([]);
  const handleLeadChange = (
    event: SelectChangeEvent<string>,
    child: ReactNode
  ) => {
    setSelectedLeadSource(event.target.value as string);
  };
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setAlternativeReportName(event.target.value as string);
  };

  const builderId = useQuerySearch("cid");

  // const { rowData: bookingList } = useGetCustomerEnquiryDetails(
  //   filters,
  //   pagination,
  //   setPagination,
  //   builderId || ""
  // );

  // const { rowData: enquiryIframeDetails } = useGetEnquiryIframeDetails(
  //   filters,
  //   pagination,
  //   setPagination,
  //   builderId || ""
  // );

  const getEnquiryData = useCallback(async () => {
    if (
      user?.role === ROLES.builder_admin ||
      ROLES.super_admin ||
      ROLES.project_manager ||
      ROLES.channel_partner ||
      ROLES.telecaller
    ) {
      const filter = {
        builder_object_id: builderId,
      };
      console.log("====>api", filter);
      const response = await api.booking.getBooking(filter);
      if (response) {
        setIncornersLeadDetails(response.result);
        return response;
      }
    } else if (user?.role === "MARKETER" || "RESELLER") {
      const filter = {
        page: 1,
        sortField: "updatedAt",
        assigned_admin_object_id: user?._id,
      };
      console.log("====>api", filter);
      const response = await api.booking.getBooking(filter);
      if (response) {
        return response;
      }
    } else {
      const response = await api.booking.getBooking({});
    }
  }, [builderId]);

  const fetchEnquiryIframe = useCallback(async () => {
    try {
      const filter = {
        builder_object_id: builderId,
      };
      const response = await api.iframe.getEnquiryIframe(filter);
      if (response) {
        setWebsiteLeadDetails(response.result);
        return response;
      }
    } catch (error) {
      console.error("error:", error);
    }
  }, [builderId]);

  useEffect(() => {
    fetchEnquiryIframe();
  }, [fetchEnquiryIframe]);
  useEffect(() => {
    getEnquiryData();
  }, [getEnquiryData]);
  useEffect(() => {
    setDashboardHeader("Leads Report");
  }, [setDashboardHeader]);
  return (
    <>
    {!permissionDetails || permissionDetails?.leads_report.has_read_access === true ? 
    <>
    <div>
    <h3>Lead Report Generation</h3>
    <div className="button-container" style={{ marginTop: "20px" }}>
      <DownloadButton
      apiCall={
        selectedLeadSource === "Website Leads"
        ? fetchEnquiryIframe
        : getEnquiryData
      }
      fileName={`${
        alternativeReportName ? alternativeReportName : selectedLeadSource
      }  Report`}
      button_name="Download Leads Report"
      key={"download_projects"}
      />
    </div>
    <Accordion defaultExpanded sx={{ marginTop: "20px", padding: "20px" }}>
      <AccordionDetails>
      <div className="input-field-container">
        <label>Select Lead Source:</label>
        <FormControl sx={{ width: "600px" }} variant="outlined">
        <InputLabel id="project-select-label">
          Select lead source
        </InputLabel>
        <Select
          labelId="project-select-label"
          id="project-select"
          value={selectedLeadSource}
          onChange={handleLeadChange}
          label="Select Project"
        >
          <MenuItem value="Incorners Leads">Incorners Leads</MenuItem>
          <MenuItem value="Website Leads">Website Leads</MenuItem>
        </Select>
        </FormControl>
      </div>

      <div className="input-field-container">
        <label>Alternative Report Name:</label>
        <TextField
        id="outlined-basic"
        variant="outlined"
        onChange={handleChange}
        sx={{ width: "600px", marginBottom: "20px" }}
        />
      </div>
      </AccordionDetails>
    </Accordion>
    </div>

    {selectedLeadSource ? (
    selectedLeadSource === "Incorners Leads" ? (
      <>
      <h3>Incorners Leads Report Details</h3>
      <DataGrid
        rowData={incornersLeadDetails}
        colDefs={enquiryColumnDef}
      />{" "}
      </>
    ) : (
      <>
      <h3>Website Leads Report Details</h3>
      <DataGrid
        rowData={websiteLeadDetails}
        colDefs={enquiryColumnDef}
      />{" "}
      </>
    )
    ) : null}
    </>
     :
    <NoAccess/>
    }
      
    </>
  );
};

export default LeadReport;
