import React, { useContext, useState } from "react";
import CheckboxCellRenderer from "../../../../../shared/cellRenderer/CheckboxCellRenderer";
import useUpdateEmployeePermission from "../../../../../../utils/hooks/updateEmployeePermission/useUpdateEmployeePermission";
import AuthContext from "../../../../../../contexts/authContext/authContext";
import { ROLES } from "../../../../../../constants/roles/Roles";

interface PermissionCellRendererProps {
  data: any;
  fieldName: string;
}

const PermissionCellRenderer= (
  {value}: {value: boolean},
) => {
  console.log("===>data", value);
  const {user} = useContext(AuthContext);
  const [isActive] = useState<boolean>(user?.role === ROLES.builder_admin || user?.role === ROLES.super_admin);
  const { updateEmployeePermission } = useUpdateEmployeePermission();
  const [permission, setPermission] = useState<boolean>(
   value
  );

  const handleChange = async () => {
    // const payload = {
    //   admin_object_id: data._id,
    //   [fieldName]: !permission,
    // };
    // const response = await updateEmployeePermission(payload);

    // if (response) {
    //   setPermission(!permission);
    // }
  };
  return <CheckboxCellRenderer value={permission} handleChnage={handleChange} isDisabled={!isActive} />;
};

export default PermissionCellRenderer;
