import { api } from '../../../../../utils/api';
import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import {
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  TextField,
} from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import dayjs from "dayjs";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const LeadsBarChart = () => {
    interface LeadData {
      day: string;
      count: number;
    }

    const [leadsData, setLeadsData] = useState<LeadData[]>([]);
    const [filter, setFilter] = useState("daily");
    const [startDate, setStartDate] = useState<Date | null>(new Date());
    const [selectedMonth, setSelectedMonth] = useState<number | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const fetchLeadsData = async () => {
       

        try {
            const params: any = {
                filterType: filter,
            };

            if (filter === "date-to-date") {
                if (!startDate || !endDate) {
                    setError("Start Date and End Date are required.");
                    return;
                }
                params.startDate = dayjs(startDate).format("YYYY-MM-DD");
                params.endDate = dayjs(endDate).format("YYYY-MM-DD");
            }

            // if (filter === "monthly") {
            //     if (selectedMonth === null) {
            //         setError("Please select a month.");
            //         return;
            //     }
            //     params.month = dayjs().month(selectedMonth).format("YYYY-MM-DD");
            // }

            console.log("--parameters:", params);

            const response = await api.dashboard.getDailyLeadsCount(params);
            console.log("===>response from api", response);

            if (response) {
                setLeadsData(response);
            }
        } catch (error) {
            console.error("API call failed:", error);
            setError("Failed to load data. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchLeadsData();
    }, [filter, startDate, endDate]);

    useEffect(() => {
        console.log("Leads Data:", leadsData);
    }, [leadsData]);

    // Chart.js Data Configuration
    const chartData = {
        labels: leadsData.map((item) => item.day), // Default labels
        datasets: [
            {
                label: "Leads Count",
                data: leadsData.map((item) => item.count),
                backgroundColor: "rgba(54, 162, 235, 0.6)",
                borderColor: "rgba(54, 162, 235, 1)",
                borderWidth: 1,
            },
        ],
    };

    // Chart.js Options
    const chartOptions = {
        responsive: true,
        plugins: {
            legend: { display: true, position: "top" as const },
            tooltip: { enabled: true },
        },
        scales: {
            x: { title: { display: true, text: filter === "monthly" ? "Day of Month" : "Day" } },
            y: { title: { display: true, text: "Leads Count" }, beginAtZero: true },
        },
    };

    return (
        <div style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", padding: "20px" , width: "700px"}}>
            <h2>Leads Count Bar Chart</h2>
            <div style={{ width: "100%", height: "auto", padding: "20px", display: "flex", alignItems: "center", justifyContent: "start", gap: "20px" }}>
            {/* Filter Dropdown */}
            <FormControl sx={{ minWidth: 150 }}>
                <InputLabel>Filter by</InputLabel>
                <Select value={filter} onChange={(e) => setFilter(e.target.value)}>
                <MenuItem value="daily">Daily</MenuItem>
                <MenuItem value="weekly">Weekly</MenuItem>
                <MenuItem value="monthly">Monthly</MenuItem>
                <MenuItem value="date-to-date">Date-to-Date</MenuItem>
                </Select>
            </FormControl>

            {/* Date Picker for Different Filters */}
            {/* {["daily", "weekly"].includes(filter) && (
                <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                dateFormat="yyyy-MM-dd"
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                customInput={<TextField fullWidth label="Start Date" />}
                />
            )} */}

            {filter === "date-to-date" && (
                <>
                <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    dateFormat="yyyy-MM-dd"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    customInput={<TextField fullWidth label="Start Date" />}
                />
                <DatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    dateFormat="yyyy-MM-dd"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    customInput={<TextField fullWidth label="End Date" />}
                />
                </>
            )}

            {filter === "monthly" && (
                <FormControl sx={{ minWidth: 150 }}>
                <InputLabel>Select Month</InputLabel>
                <Select
                    value={selectedMonth !== null ? selectedMonth : ""}
                    onChange={(e) => setSelectedMonth(Number(e.target.value))}
                >
                    {[
                    "January", "February", "March", "April", "May", "June",
                    "July", "August", "September", "October", "November", "December"
                    ].map((month, index) => (
                    <MenuItem key={month} value={index}>{month}</MenuItem>
                    ))}
                </Select>
                </FormControl>
            )}
            </div>

          
            {/* No Data Found Message */}
            {!loading && leadsData.length === 0 && <p>No data available.</p>}

            {/* Bar Chart */}
            {leadsData.length > 0 ?  
            <div style={{ width: "auto", height: 300 }}>
            <Bar data={chartData} options={chartOptions} />
        </div> : null}
            
        </div>
    );
};

export default LeadsBarChart;
