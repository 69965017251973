import React, { useContext } from "react";
import AuthContext from "../../../../../../../contexts/authContext/authContext";
import { ROLES } from "../../../../../../../constants/roles/Roles";
import { Button } from "@mui/material";
import { PublishPlot } from "../../../../../../shared/publishPlot/PublishPlot";
import SaveIcon from "@mui/icons-material/Save";
import { ILayout } from "../../../../../../../@types/interface/layout.interface";

const ButtonContainer = ({
  mode,
  handleDraftPlot,
  newlistedPlot,
  handlePublishPlot,
}: {
  mode: "ASSIGN_PLOT" | "DEFAULT";
  handleDraftPlot: () => Promise<void>;
  newlistedPlot: (ILayout | { assigned_to: string | null })[];
  handlePublishPlot: () => Promise<void>;
}) => {
  const { user } = useContext(AuthContext);
  return (
    <div className="button-container">
      {/* <Button onClick={handleRemove}>Remove Selections</Button> */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          gap: 10,
        }}
      >
        {user?.role === ROLES.super_admin ? (
          <>
            <Button
              variant="contained"
              type="submit"
              startIcon={<SaveIcon />}
              sx={{
                background: "black",
                textAlign: "center",
                fontSize: "12PX",
              }}
              onClick={handleDraftPlot}
            >
              {newlistedPlot.length > 0 || mode === "DEFAULT"
                ? "Save & Next"
                : "Save Draft"}
            </Button>
            {newlistedPlot.length > 0 || mode === "DEFAULT" ? null : (
              <PublishPlot handlePublishPlot={handlePublishPlot} />
            )}
          </>
        ) : null}
      </div>
    </div>
  );
};

export default ButtonContainer;
