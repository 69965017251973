import { colors } from "@mui/material";

const colorConfigs = {
  sidebar: {
    bg: "#fff2e6",
    color: "#001633",
    hoverBg: "#ffc999",
    border: " #808080",
    activeBg: "#ffe6cc",
  },
  adminSidebar: {
    bg: "#ffffe6",
    color: "#001f4d",
    hoverBg: "#ffffb3",
    activeBg: "#ffff99",
  },
  topbar: {
    bg: "#fff",
    color: "#000",
  },
  mainBg: colors.grey["100"],
};

export default colorConfigs;
