import React, { useContext } from 'react'
import { Outlet } from 'react-router-dom'
import PermissionContext from '../../../contexts/permissionContext/permissionContext'

const Projects = () => {
  return (
    <div>
    
      <Outlet/>
    </div>
  )
}

export default Projects