import { ColDef } from "ag-grid-community";
import EmployeePermissionCellRenderer from "../employeePermissionCellRenderer/EmployeePermissionCellRenderer";
import FormattedDate from "./formattedDate/FormattedDate";

export const EmployeeDataColDefs : ColDef[] = [
    { field: "full_name", headerName: "Name", flex: 1 },
    { field: "email", headerName: "Email", flex: 1 },
    { field: "phone_number", headerName: "Phone No.", flex: 1 },
    { field: "role", headerName: "Role", flex: 1 },
    { field: "last_login_date", headerName: "Last login date", flex: 1, cellRenderer: FormattedDate},
    { field: "createdAt", headerName: "Created on", flex: 1 , cellRenderer: FormattedDate},
    { field: "actions", headerName: "Actions", flex: 1, cellRenderer: EmployeePermissionCellRenderer },
]