import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import MainLayout from "./components/layout/MainLayout";
import { routes } from "./routes";
import Login from "./components/pages/auth/login/Login";
import PhoneLogin from "./components/pages/auth/otpLogin/PhoneLogin";
import VerifyLogin from "./components/pages/auth/otpLogin/VerifyLogin";
import { useContext } from "react";
import AuthContext from "./contexts/authContext/authContext";
import Profile from "./components/pages/auth/profile/Profile";
import IframeView from "./components/pages/iframeView/IframeView";
import useVerifyToken from "./utils/hooks/useVerifyToken/useVerifyToken";
import ProjectLayout from "./components/pages/projectLayout/ProjectLayout";

function App() {
  const { verified, hasFetched } = useVerifyToken();
  const {user} = useContext(AuthContext);
  
  if(!hasFetched){
    return <div>Loading...</div>
  }
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={!verified ? user ? <MainLayout /> : <Navigate to="/login" /> : <MainLayout />}
        >
          {routes}
        </Route>
        {/* <Route path="/admin" element={<MainLayout />}>
          {routes}
        </Route> */}
        <Route
          path="/builder-project/:builderId/:projectId"
          element={<IframeView />}
        />
        <Route path="/project-layout" element={<ProjectLayout/>} />
        <Route path="/login" element={<Login />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/reseller/profile" element={<Profile />} />
        <Route path="/admin/profile" element={<Profile />} />
        <Route path="/landlord/profile" element={<Profile />} />
        <Route path="/marketer/profile" element={<Profile />} />
        <Route path="/admin/phone-login" element={<PhoneLogin />} />
        <Route path="/admin/verify-login" element={<VerifyLogin />} />
        {/* <Route path="/upoad-layout" element={<UploadLayout />} /> */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
