import React, { useContext, useEffect, useState } from "react";

import { LatestLeadColDefs } from "./latestLeadColDefs/LatestLeadColDefs";
import UIContext from "../../../../../contexts/uiContext/UIContext";
import DataGrid from "../../../../shared/dataGrid/DataGrid";
import { enquiryColumnDef } from "../../../../../constants/enquiry/EnquiryColDef";
import useGetLatestLeads from "../../../../../utils/hooks/getLatestLeads/useGetLatestLeads";
import useQuerySearch from "../../../../hooks/querySearch/useQuerySearch";
import { IPagination } from "../../../../../@types/interface/pagination";
import BasicPagination from "../../../../shared/basicPagination/BasicPagination";
import { PermissionColDefs } from "../../../../../constants/permission/permissionColDefs";
import PermissionContext from "../../../../../contexts/permissionContext/permissionContext";
import NoAccess from "../../../../shared/noAccess/NoAccess";

const LatestLeads = () => {
  const { setDashboardHeader } = useContext(UIContext);
  const [filters, setFilters] = useState({});
  const [pagination, setPagination] = useState<IPagination>({
    currentPage: 1,
    pageCount: 1,
  });
const {permissionDetails} = useContext(PermissionContext)
  const { rowData: latestLeads } = useGetLatestLeads(
    filters,
    pagination,
    setPagination
  );

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPagination((prev) => ({
      ...prev,
      currentPage: value,
    }));
  };
  useEffect(() => {
    setDashboardHeader("Latest Leads");
  }, [setDashboardHeader]);
  return (
    <>
    {!permissionDetails || permissionDetails?.latest_leads.has_read_access === true ? 
    <div>
    <DataGrid rowData={latestLeads} colDefs={enquiryColumnDef} />
    <BasicPagination
      currentPage={pagination.currentPage}
      handlePageChange={handlePageChange}
      pageCount={pagination.pageCount}
    />
  </div>
  : 
  <NoAccess/>
  }
    
    </>
  );
};

export default LatestLeads;
