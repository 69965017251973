import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect } from "react";
import UIContext from "../../../../contexts/uiContext/UIContext";
import SendIcon from "@mui/icons-material/Send";
import PermissionContext from "../../../../contexts/permissionContext/permissionContext";
import NoAccess from "../../../shared/noAccess/NoAccess";

const PhaseReport = () => {
  const { setDashboardHeader } = useContext(UIContext);
  const { permissionDetails } = useContext(PermissionContext);
  useEffect(() => {
    setDashboardHeader("Phase Report");
  }, [setDashboardHeader]);
  return (
    <>
      {!permissionDetails ||
      permissionDetails?.phase_report.has_read_access === true ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "70vh",
          }}
        >
          <h1>Coming Soon</h1>
        </div>
      ) : (
        <NoAccess />
      )}
    </>
  );
};

export default PhaseReport;
