import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import UIContext from "../../../../contexts/uiContext/UIContext";
import { BarChart, DashboardCard, PieChart } from "../../../shared";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import ApartmentIcon from "@mui/icons-material/Apartment";
import GroupsIcon from "@mui/icons-material/Groups";
import BookmarkAddedIcon from "@mui/icons-material/BookmarkAdded";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Box, IconButton } from "@mui/material";
import { formatMongoDate } from "../../../../utils/commonFunction/formatMongoDate";
import {
  sevenDayNext,
  sevenDayPrev,
  todayDate,
} from "../../../../utils/commonFunction/dateCalculation";
import useGetBUildersDashboardData from "../../../../utils/hooks/buildersDashboardData/useGetBuildersDashboardData";
import useGetDailyLeads from "../../../../utils/hooks/useGetDailyLeads/useGetDailyLeads";
import LeadsBarChart from "./leadsBarChart/LeadsBarChart";
import PermissionContext from "../../../../contexts/permissionContext/permissionContext";
import NoAccess from "../../../shared/noAccess/NoAccess";

const BuildersDashboard = () => {
  const { setDashboardHeader } = useContext(UIContext);
  const { buildersDashboardData } = useGetBUildersDashboardData();
  const [switchValue, setSwitchValue] = useState<boolean>(false);
  const [leadsSearchDate, setLeadsSearchDate] = useState<string>(todayDate());
  const [filterType, setFilterType] = useState<string>("");
  const { permissionDetails } = useContext(PermissionContext);

  // Pass the dynamic startDate and endDate to the custom hook
  // const { leadsData, loading, error } = useGetDailyLeads({
  //   filterType,
  //   startDate: "2024-11-25",
  //   endDate: "2024-12-01" ,
  //   date: "2024-11-25"
  // });

  const handleSwitchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSwitchValue(event.target.checked);
  };

  const handleChangeLeadsSearchDate = (type: "PREV" | "NEXT") => {
    if (type === "PREV") {
      const sevenDayAgo = sevenDayPrev(leadsSearchDate);
      setLeadsSearchDate(sevenDayAgo);
    } else {
      const nextSevenDay = sevenDayNext(leadsSearchDate);
      setLeadsSearchDate(nextSevenDay);
    }
  };

  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    setFilterType(selectedValue);
    console.log("selected", selectedValue);
  };
  // const barChartData = leadsData.map((item) => ({
  //   day: item.day, // or item.date if you prefer the full date
  //   value: item.count,
  //   color: "hsl(200, 70%, 50%)", // Customize color if needed
  // }));

  const builder = [
    {
      id: "Channel Partner",
      label: "Channel Partner",
      value: buildersDashboardData?.channelPartnerLeads || 0,
      color: "hsl(157, 70%, 50%)",
    },
    {
      id: "Incorners Leads",
      label: "Incorners Leads",
      value: buildersDashboardData?.numberOfIncornersLeads || 0,
      color: "hsl(52, 70%, 50%)",
    },
    {
      id: "Outside Leads",
      label: "Outside Leads",
      value: buildersDashboardData?.numberOfOutsideLeads || 0,
      color: "hsl(198, 70%, 50%)",
    },
  ];

  useEffect(() => {
    setDashboardHeader("Dashboard Analytics");
  }, [setDashboardHeader]);

  return (
    <div className="home-page">
      {!permissionDetails || permissionDetails?.dashboard.has_read_access ? (
        <>
          <div className="cards-section">
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "nowrap",
                justifyContent: "center",
                gap: "0.5rem",
                padding: "1rem",
              }}
            >
              <DashboardCard
                cardTitle={"Total Projects"}
                cardValue={buildersDashboardData?.numberOfAssignedProjects || 0}
                cardIcon={<AssignmentTurnedInIcon sx={{ color: "#153448" }} />}
              />
              <DashboardCard
                cardTitle={"Total Layouts"}
                cardValue={0}
                cardIcon={<ApartmentIcon sx={{ color: "#153448" }} />}
              />
              <DashboardCard
                cardTitle={"Total Leads"}
                cardValue={
                  buildersDashboardData
                    ? buildersDashboardData.numberOfIncornersLeads +
                      buildersDashboardData.numberOfOutsideLeads
                    : 0
                }
                cardIcon={<GroupsIcon sx={{ color: "#153448" }} />}
              />
              <DashboardCard
                cardTitle={"Total Bookings"}
                cardValue={buildersDashboardData?.numberOfBookings || 0}
                cardIcon={<BookmarkAddedIcon sx={{ color: "#153448" }} />}
              />
            </Box>
          </div>

          <div className="analytics-container">
            <div
              style={{
                width: "500px",
                height: 400,
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                padding: "20px",
              }}
            >
              <h2>Leads Analytics</h2>
              <PieChart pieChartData={builder} />
            </div>
            <LeadsBarChart />
          </div>

          {/* <div className="time-selection">
               <label htmlFor="time-select">Select Time Period: </label>
               <select id="time-select" onChange={handleChange}>
                 <option value="daily">Daily</option>
                 <option value="weekly">Weekly</option>
                 <option value="monthly">Monthly</option>
                 <option value="date-to-date">Date to Date</option>
               </select>
             </div>

             <div className="bottom-section">
               {leadsData.length > 0 ? (
                 <p style={{height: "0px", textAlign: "center"}}>
                   <IconButton onClick={() => {handleChangeLeadsSearchDate("PREV")}}><ChevronLeftIcon fontSize={"small"}/></IconButton>
                   <span>{formatMongoDate(leadsData[0].date)} </span>-
                   <span>{formatMongoDate(leadsData[leadsData.length - 1].date)}</span>
                   <IconButton onClick={() => {handleChangeLeadsSearchDate("NEXT")}}><ChevronRightIcon fontSize={"small"}/></IconButton>
                 </p>
               ) : null}
               <BarChart barChartData={leadsData} />
             </div> */}
        </>
      ) : (
        <NoAccess />
      )}
    </div>
  );
};

export default BuildersDashboard;
