import React from "react";
import { IconButton } from "@mui/material";
import CropSquareIcon from "@mui/icons-material/CropSquare";
import FormatShapesIcon from "@mui/icons-material/FormatShapes";
import DeleteIcon from "@mui/icons-material/Delete";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import UndoIcon from "@mui/icons-material/Undo";
import RedoIcon from "@mui/icons-material/Redo";
import { IToolBoxProps } from "../../../../@types/props/ToolBox.props";

const ToolBox: React.FC<IToolBoxProps> = ({handleRemove, finishPath, handleAddPlotBox, handleAddPlotPolygon}) => {
  return (
    <div className="tool-box">
      <IconButton onClick={handleAddPlotBox}>
        <CropSquareIcon color="primary" />
      </IconButton>
      <IconButton onClick={handleAddPlotPolygon}>
        <FormatShapesIcon color="secondary" />
      </IconButton>
      <IconButton onClick={handleRemove}>
        <DeleteIcon color="error" />
      </IconButton>
      <IconButton onClick={finishPath}>
        <DoneOutlineIcon color="success" />
      </IconButton>
      <IconButton>
        <UndoIcon color="warning" />
      </IconButton>
      <IconButton>
        <RedoIcon color="warning" />
      </IconButton>
    </div>
  );
};

export default ToolBox;
