import React, { useContext } from 'react'
import PermissionContext from '../../../../contexts/permissionContext/permissionContext'

const ProjectManagement = () => {
  const {permissionDetails} = useContext(PermissionContext)
  return (
    <div>
      {!permissionDetails || permissionDetails?.project_management?.has_read_access ? 
      <>
      <h1>Coming soon</h1>
      </> 
      : 
      <h1>No access given</h1>
      }
    </div>
  )
}

export default ProjectManagement
