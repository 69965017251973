// export const url = "http://localhost:8989";
export const url = "https://dnzss3elbad3r.cloudfront.net";
export const port = "8989";
export const version = "v1";

export const headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
};
const ENV: "PROD" | "LOCAL" = "LOCAL";

const PROD_URL = "https://incorners.com";
const LOCAL_URL = "http://localhost:3000";

export const IFRAME_BASE = String(ENV) === "PROD" ? PROD_URL : LOCAL_URL;
