import React, { useContext, useEffect, useState } from 'react'
import { FilterModel } from 'ag-grid-community';
import UIContext from '../../../../../contexts/uiContext/UIContext';
import { IPagination } from '../../../../../@types/interface/pagination';
import useQuerySearch from '../../../../hooks/querySearch/useQuerySearch';
import useGetCustomerEnquiryDetails from '../../../../hooks/customerEnquiryDetails/useGetCustomerEnquiryDetails';
import { enquiryColumnDef } from '../../../../../constants/enquiry/EnquiryColDef';
import DataGrid from '../../../../shared/dataGrid/DataGrid';
import BasicPagination from '../../../../shared/basicPagination/BasicPagination';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { PermissionColDefs } from '../../../../../constants/permission/permissionColDefs';
import PermissionContext from '../../../../../contexts/permissionContext/permissionContext';

const IncornersLeads = () => {
    const {setDashboardHeader} = useContext(UIContext);
    const [filters, setFilters] = useState([]);
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
  const {permissionDetails} = useContext(PermissionContext)
    const [pagination, setPagination] = useState<IPagination>({
      currentPage: 1,
      pageCount: 1,
    });

    const handlePageChange = (
        event: React.ChangeEvent<unknown>,
        value: number
      ) => {
        setPagination((prev) => ({
          ...prev,
          currentPage: value,
        }));
      };
      const onRowClicked = (event: { data: any }) => {
        const rowData = event.data;
        setSelectedRowData(rowData);
      };
 const onFilterChange = (filterModel: FilterModel) => {
      setFilters((prevFilters: any) => {
        const sanitizedFilters = { ...prevFilters };
        Object.keys(sanitizedFilters).forEach((key: any) => {
          if (!filterModel[key]) {
            delete sanitizedFilters[key];
          }
        });
        const updatedFilters = { ...sanitizedFilters, ...filterModel };
        console.log("Updated Filters-->", updatedFilters);
        return updatedFilters;
      });
    };

    const builderId = useQuerySearch("cid");
  
    const { rowData: bookingList } = useGetCustomerEnquiryDetails(
      filters,
      pagination,
      setPagination,
      builderId || ""
    );

    useEffect(() => {
        setDashboardHeader("Incorners Leads")
    },[setDashboardHeader])
  return (
    <>
    {!permissionDetails || permissionDetails?.incorners_leads.has_read_access ?
    <div>
    <div style={{display: "flex", flexDirection: "column", marginBottom: "30px"}}>
                 <h3>Select Time</h3>
 <FormControl sx={{width: "400px"}} variant="outlined">
           <InputLabel id="project-select-label">
             Select time
           </InputLabel>
           <Select
             labelId="time-select-label"
             id="time-select"
             value={selectedDate}
             
             label="Select Time"
           >
             <MenuItem value="last_week">Last Week</MenuItem>
             <MenuItem value="last_month">Last Month</MenuItem>
             <MenuItem value="last_6_months">Last 6 Months</MenuItem>
           </Select>
         </FormControl>
                 </div>
   <DataGrid rowData={bookingList} colDefs={enquiryColumnDef} 
     onFilterChange={onFilterChange}/>
   <BasicPagination
     currentPage={pagination.currentPage}
     handlePageChange={handlePageChange}
     pageCount={pagination.pageCount}
   />
</div>
:
<h1>No access given</h1>
    }
    
    </>
  )
}

export default IncornersLeads