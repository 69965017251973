/* eslint-disable react-hooks/exhaustive-deps */

import { useReducer, useCallback } from "react";
import { ContextProviderProps } from "../../@types/contexts/context.types";
import reducer from "./reducer";
import { IPermissionManagment } from "../../@types/interface/permissionManagment.interface";
import PermissionContext from "./permissionContext";
import actions from "./action";
import { Store } from "../../@types/contexts/permissionContext/store.types";


const initialState: Store = {
  permissionDetails: null,
};
const PermissionContextProvider = ({ children }: ContextProviderProps) => {
	const [state, dispatch] = useReducer(reducer, initialState);
	const value = {
		permissionDetails: state.permissionDetails,
		setPermissionDetails: useCallback((permissionDetails: IPermissionManagment) => {
			dispatch({ type: actions.SET_PERMISSION, payload: { ...state, permissionDetails } });
		}, []),
	};

	return <PermissionContext.Provider value={value}>{children}</PermissionContext.Provider>;
};

export default PermissionContextProvider;
