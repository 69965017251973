import React, { useCallback, useEffect, useState } from "react";
import { IProject } from "../../../@types/interface/Projects";
import { ILayout } from "../../../@types/interface/layout.interface";
import { api } from "../../../utils/api";

const useGetProjectDetails = (projectId: string | undefined | null) => {
  const [project, setProject] = useState<IProject | null>(null);
  const [layoutList, setLayoutList] = useState<ILayout[]>([]);

  const getProjectDetails = useCallback(async () => {
    if (!projectId) return;
    const payload = {
      project_object_id: projectId,
    };
    const projectDetails = await api.project.getProjectDetails(payload);
    if (projectDetails) {
      setProject(projectDetails.project_details);
      setLayoutList(projectDetails.layout_list);
    }
  }, [projectId]);

  useEffect(() => {
    getProjectDetails();
  }, [getProjectDetails]);

  return {
    project,
    layoutList,
    setLayoutList
  };
};

export default useGetProjectDetails;
