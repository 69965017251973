import React from 'react'

import LockIcon from '@mui/icons-material/Lock';


const NoAccess = () => {
    return (
        <div
          style={{
            width: "full",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "70vh",
          }}
        >
            
            <h1 >No Access Given
          
            </h1>
         
        </div>
    );
}

export default NoAccess;