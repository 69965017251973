import React, { useCallback, useContext, useEffect, useState } from "react";
import UIContext from "../../../../contexts/uiContext/UIContext";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { useNavigate } from "react-router-dom";
import BuilderContext from "../../../../contexts/builderContext/BuilderContext";
import GroupAddOutlinedIcon from "@mui/icons-material/GroupAddOutlined";
import { Button, SelectChangeEvent } from "@mui/material";
import { api } from "../../../../utils/api";
import "./employee.css";
import BasicPagination from "../../../shared/basicPagination/BasicPagination";
import Download from "../../../shared/downloadbtn/Download";
import { IPagination } from "../../../../@types/interface/pagination";
import { IEmployee } from "../../../../@types/interface/employee.interface";
import PermissionContext from "../../../../contexts/permissionContext/permissionContext";
import { EmployeeDataColDefs } from "./employeeDataColDefs/EmployeeDataColDefs";

const EmployeeDetails = () => {
  const navigate = useNavigate();
  const { setDashboardHeader } = useContext(UIContext);
  const { builderDetails } = useContext(BuilderContext);
  const [employeeList, setEmployeeList] = useState<IEmployee[]>([]);
  const [pagination, setPagination] = useState<IPagination>({
    currentPage: 1,
    pageCount: 1
  })
  const {permissionDetails} = useContext(PermissionContext)
  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPagination((prev) => ({
      ...prev,
      currentPage: value,
    }));
  };

  const getEmployeeList = useCallback(async () => {
    try {
      if(!builderDetails){
        return;
      }
      const filter = {
        page: 1,
        sortField: "updatedAt",
        builder_object_id: builderDetails?._id,
      };
      const response = await api.admin.getEmployeeList(filter);
      setEmployeeList(response.result);
    } catch (error) {
      console.log("Error in getting employee list", error);
    }
  }, [builderDetails?._id]);

  const getFullEmployeeList = useCallback(async () => {
    try {
      let response: any = {};
      const filter = {
        page: 1,
        sortField: "updatedAt",
        builder_object_id: builderDetails?._id || "",
      };
       response = await api.admin.getEmployeeList(filter);
       if(response){
        setEmployeeList(response.result);
        return response
      
       }
    
    } catch (error) {
      console.log("Error in getting full employee list", error);
    }
  }, [builderDetails?._id]);

  const handleNavigateToAddEmployee = () => {
    navigate(`/admin/add-employee?cid=${builderDetails?._id}`);
  };

  useEffect(() => {
    setDashboardHeader("Employee Details");
  }, [setDashboardHeader]);

  useEffect(() => {
    getEmployeeList();
  }, [getEmployeeList]);
  return (
    <div>
      {!permissionDetails || permissionDetails?.employees.has_read_access === true ? 
      <>
      <div className="employee-container">
      <Download
        apiCall={getFullEmployeeList}
        button_name="Download Employee Report"
        fileName="employee_database"
        key={"builder_employee"}
      />
      <Button
        className="btn"
        style={{ fontSize: "15px" }}
        endIcon={<GroupAddOutlinedIcon />}
        onClick={handleNavigateToAddEmployee}
      >
        Add employee
      </Button>
    </div>
    <div className="ag-theme-alpine" style={{ height: 300 }}>
      <AgGridReact rowData={employeeList} columnDefs={EmployeeDataColDefs} />
      <BasicPagination pageCount={pagination.pageCount} handlePageChange={handlePageChange} currentPage={pagination.currentPage}/>
    </div>
    </> :
    <h1>No access given</h1>
    }
      
    </div>
  );
};


export default EmployeeDetails;
