import { useContext, useEffect } from "react";
import UIContext from "../../../../../contexts/uiContext/UIContext";
import useQuerySearch from "../../../../hooks/querySearch/useQuerySearch";
import AddEmployee from "../addEmployee/AddEmployee";
import useGetEmployeeDetails from "../../../../../utils/hooks/useGetEmployeeDetails/useGetEmployeeDetails";
import { PermsissionColDefs } from "../employeeColDefs/PermissionColDefs";
import { transformPermissions } from "../../../../../utils/commonFunction/employeePermissionFormatter";
import DataGrid from "../../../../shared/dataGrid/DataGrid";

const EmployeePermissionDetails = () => {
  const employeeId = useQuerySearch("employee_id"); // Ensure it matches backend parameter
  const { setDashboardHeader } = useContext(UIContext);
  const {employeeDetails} = useGetEmployeeDetails(employeeId);
  const rowData = transformPermissions(employeeDetails?.permissions);
  
  useEffect(() => {
    setDashboardHeader("Employee Permission Details");
  }, [setDashboardHeader]);

  return (
    <div>
      {
        employeeDetails ? (
          <AddEmployee mode="EDIT" employee_details={employeeDetails} />
        ) : null
      }
      <div className="ag-theme-alpine" style={{ height: 300, marginTop: 22 }}>
        <DataGrid rowData={rowData} colDefs={PermsissionColDefs} />
      </div>
    </div>
  );
};

export default EmployeePermissionDetails;
