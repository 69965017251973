import React, { useCallback, useContext, useEffect, useState } from "react";
import AuthContext from "../../../contexts/authContext/authContext";
import PermissionContext from "../../../contexts/permissionContext/permissionContext";
import { api } from "../../api";

const useVerifyToken = () => {
  const { setUser } = useContext(AuthContext);
  const { setPermissionDetails } = useContext(PermissionContext);
  const [verified, setVerified] = useState<boolean>(false);
  const [hasFetched, setHasFetched] = useState<boolean>(false);

  const verifyUserLoggedin = useCallback(async () => {
    try {
      const response = await api.auth.verifyAdmin();
      if (response) {
        setUser(response.adminInstance);
        setPermissionDetails(response.permissionDetails);
        setVerified(true);
      }
    } catch (error) {
      console.log(error);
      localStorage.removeItem("token");
    } finally {
      setHasFetched(true);
    }
  }, []);

  useEffect(() => {
    verifyUserLoggedin();
  }, [verifyUserLoggedin]);

  return { verified, hasFetched };
};

export default useVerifyToken;
