import React from 'react'
import { Payload } from '../../../@types/api/api.types'
import { api } from '../../api'

const useUpdateEmployeePermission = () => {
    const updateEmployeePermission = async (payload: Payload) => {
        try{
            const response = await api.admin.updateEmployeePermission(payload);
            return response;
        } catch (error) {
            console.log(error)
            alert('Error in updating employee permission')
        }
    }
  return { updateEmployeePermission }
}

export default useUpdateEmployeePermission