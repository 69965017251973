import React, { useContext, useEffect, useState } from "react";
import { FilterModel } from "ag-grid-community";
import UIContext from "../../../../../contexts/uiContext/UIContext";
import { IPagination } from "../../../../../@types/interface/pagination";
import useQuerySearch from "../../../../hooks/querySearch/useQuerySearch";
import useGetEnquiryIframeDetails from "../../../../hooks/enquiryIframeDetails/useGetEnquiryIframeDetails";
import DataGrid from "../../../../shared/dataGrid/DataGrid";
import { EnquiryIframeColDefs } from "../../enquiryIframe/enquiryIframeColdefs/enquiryIframeColdefs";
import { enquiryColumnDef } from "../../../../../constants/enquiry/EnquiryColDef";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import PermissionContext from "../../../../../contexts/permissionContext/permissionContext";

const WebsiteLeads = () => {
  const { setDashboardHeader } = useContext(UIContext);
  const [filters, setFilters] = useState([]);
const [selectedDate, setSelectedDate] = useState("");
  const builderId = useQuerySearch("cid");
  const [pagination, setPagination] = useState<IPagination>({
    currentPage: 1,
    pageCount: 1,
  });
const {permissionDetails} = useContext(PermissionContext)
  const { rowData: enquiryIframeDetails } = useGetEnquiryIframeDetails(
    filters,
    pagination,
    setPagination,
    builderId || ""
  );

  const onFilterChange = (filterModel: FilterModel) => {
    setFilters((prevFilters: any) => {
      const sanitizedFilters = { ...prevFilters };
      Object.keys(sanitizedFilters).forEach((key: any) => {
        if (!filterModel[key]) {
          delete sanitizedFilters[key];
        }
      });
      const updatedFilters = { ...sanitizedFilters, ...filterModel };
      console.log("Updated Filters-->", updatedFilters);
      return updatedFilters;
    });
  };
  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPagination((prev) => ({
      ...prev,
      currentPage: value,
    }));
  };

  useEffect(() => {
    setDashboardHeader("Website Leads");
  }, [setDashboardHeader]);
  console.log("===>enquiry", enquiryIframeDetails);
  return (
    <>
    {!permissionDetails || permissionDetails?.website_leads.has_read_access ? 
    <div>
    <div style={{display: "flex", flexDirection: "column", marginBottom: "30px"}}>
                  <h3>Select Time</h3>
  <FormControl sx={{width: "400px"}} variant="outlined">
            <InputLabel id="project-select-label">
              Select time
            </InputLabel>
            <Select
              labelId="time-select-label"
              id="time-select"
              value={selectedDate}
              
              label="Select Time"
            >
              <MenuItem value="last_week">Last Week</MenuItem>
              <MenuItem value="last_month">Last Month</MenuItem>
              <MenuItem value="last_6_months">Last 6 Months</MenuItem>
            </Select>
          </FormControl>
                  </div>
  <DataGrid rowData={enquiryIframeDetails} colDefs={enquiryColumnDef} />
</div>
:
<h1>No access given</h1>}
    
    </>
  );
};

export default WebsiteLeads;
