import React, { useCallback, useContext, useEffect, useState } from "react";
import { PlotsDefs } from "../../../../constants/plots/plotsDefs";
import DataGrid from "../../../shared/dataGrid/DataGrid";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import AddLocationAltOutlinedIcon from "@mui/icons-material/AddLocationAltOutlined";
import { IProject } from "../../../../@types/interface/Projects";
import { api } from "../../../../utils/api";
import BuilderContext from "../../../../contexts/builderContext/BuilderContext";
import UIContext from "../../../../contexts/uiContext/UIContext";
import AuthContext from "../../../../contexts/authContext/authContext";
import { ROLES } from "../../../../constants/roles/Roles";
import "./plots.css";
import { IPagination } from "../../../../@types/interface/pagination";
import DownloadButton from "../../../shared/downloadbtn/Download";
import PermissionContext from "../../../../contexts/permissionContext/permissionContext";
type Props = {};

const AdminPlots = (props: Props) => {
  const { user } = useContext(AuthContext);
  const {permissionDetails} = useContext(PermissionContext)
  const { builderDetails } = useContext(BuilderContext);
  const { setDashboardHeader } = useContext(UIContext);
  const [projectList, setProjectList] = useState<IProject[]>([]);
  const [projectPagination, setProjectPagination] = useState<IPagination>({
    currentPage: 1,
    pageCount: 1,
  });
  const location = useLocation();

  const isOnAdminPage = location.pathname.startsWith("/admin");
  const isOnOwnerPage = location.pathname.startsWith("/landlord");
  const isOnRetailerPage = location.pathname.startsWith("/reseller");
  const isOnMarketerPage = location.pathname.startsWith("/marketer");

  // const handleNavigateToAddPlots = () => {
  //   navigate(`/admin/add-plots?cid=${builderDetails?._id}`);
  // };
  const getRole = () => {
    if (isOnAdminPage) {
      return ROLES.builder_admin;
    } else if (isOnOwnerPage) {
      return ROLES.landlord;
    } else if (isOnRetailerPage) {
      return ROLES.reseller;
    } else if (isOnMarketerPage) {
      return ROLES.marketer;
    }
  };

  const getAllProjects = useCallback(async () => {
    try {
      let response: any = {};

      const filter = {
        page: projectPagination.currentPage,
        role: getRole(),
        search_id: builderDetails ? builderDetails._id : user?._id,
      };
      response = await api.project.getAdminPlots(filter);

      if (response) {
        setProjectList(response.result);
        setProjectPagination(response.pagination);
        console.log("response", response);
      }
    } catch (error) {
      console.error("Error while fetching data:", error);
    }
  }, [builderDetails, projectPagination.currentPage, user?._id, user?.role]);

  const getAssignedProjectsReport = useCallback(async () => {
    try {
      let response: any = {};

      const filter = {
        // page: 1,
        role: getRole(),
        search_id: builderDetails ? builderDetails._id : user?._id,
      };
      response = await api.project.getAdminPlots(filter);

      if (response) {
        return response;
      }
    } catch (error) {
      console.error("Error while fetching data:", error);
    }
  }, [builderDetails, getRole, user?._id]);

  useEffect(() => {
    setDashboardHeader("All Plots");
    getAllProjects();
  }, [setDashboardHeader, getAllProjects]);

  return (
    <div>
      {!permissionDetails || permissionDetails?.project_shared_details.has_read_access ? 
      <>
      <div className="add-btn">
      <DownloadButton
        apiCall={getAssignedProjectsReport}
        fileName={`${
          builderDetails ? builderDetails?.builder_name : user?.full_name
        } Project Report`}
        button_name="Download Projects Report"
        key={"download_projects"}
      />
    </div>
    <DataGrid colDefs={PlotsDefs} rowData={projectList} key={0} /> 
    </>
    : 
    <h1>No access given</h1>
  }
      
    </div>
  );
};

export default AdminPlots;
