import React, { useCallback, useContext, useEffect, useState } from "react";
import UIContext from "../../../../contexts/uiContext/UIContext";
import { IPagination } from "../../../../@types/interface/pagination";
import DataGrid from "../../../shared/dataGrid/DataGrid";
import { ProjectChangeReqColDefs } from "./projectChangeReqColDefs/ProjectChangeReqColDefs";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import BuilderContext from "../../../../contexts/builderContext/BuilderContext";
import BasicPagination from "../../../shared/basicPagination/BasicPagination";
import useGetAllChangeRequests from "../../../../utils/hooks/useGetAllChangeRequest/useGetAllChangeRequest";
import PermissionContext from "../../../../contexts/permissionContext/permissionContext";

const ProjectChangeReq = () => {
  const { setDashboardHeader } = useContext(UIContext);
  const navigate = useNavigate();
  const { builderDetails } = useContext(BuilderContext);
  const [pagination, setPagination] = useState<IPagination>({
    currentPage: 1,
    pageCount: 1,
  });
const {permissionDetails} = useContext(PermissionContext);

 const {rowData} = useGetAllChangeRequests(pagination, setPagination);const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPagination((prev) => ({
      ...prev,
      currentPage: value,
    }));
  };

  const handleNavigateToAddChangeReq = () => {
    navigate(`/admin/add-request?cid=${builderDetails?._id}`);
  };
  useEffect(() => {
    setDashboardHeader("Project Change Request");
  }, [setDashboardHeader]);
  return (
    <div>
      {!permissionDetails || permissionDetails?.project_change_request?.has_read_access ? 
      <>
      <div className="employee-container">
      <Button
        className="btn"
        style={{ fontSize: "15px" }}
        onClick={handleNavigateToAddChangeReq}
      >
        Add Change Request
      </Button>
    </div>

    <DataGrid rowData={rowData} colDefs={ProjectChangeReqColDefs} />
    <BasicPagination pageCount={pagination.pageCount} currentPage={pagination.currentPage} handlePageChange={handlePageChange}/>
    </>
    :
    <h1>No access given</h1>
    }
      
    </div>
  );
};

export default ProjectChangeReq;
