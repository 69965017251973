import React, { useContext, useEffect, useState } from "react";
import { ChannelPartnerColDefs } from "./channelPartnerColDefs/ChannelPartnerColDefs";
import UIContext from "../../../../../contexts/uiContext/UIContext";
import DataGrid from "../../../../shared/dataGrid/DataGrid";
import PermissionContext from "../../../../../contexts/permissionContext/permissionContext";
import NoAccess from "../../../../shared/noAccess/NoAccess";

const ChannelPartnerLeads = () => {
  const { setDashboardHeader } = useContext(UIContext);
  const [details, setDetails] = useState([]);
  const {permissionDetails} = useContext(PermissionContext)

  useEffect(() => {
    setDashboardHeader("Channel Partner Leads");
  }, [setDashboardHeader]);
  return (
    // <div>
    //   <DataGrid rowData={details} colDefs={ChannelPartnerColDefs} />
    // </div>
    <>
    {!permissionDetails || permissionDetails?.channel_partner_leads.has_read_access ? 
     <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70vh' }}>
     <h1>Coming Soon</h1>
   </div>
   :
   <NoAccess/>}
   
    </>
  );
};

export default ChannelPartnerLeads;
