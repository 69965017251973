import { IPermissionManagment } from "../../@types/interface/permissionManagment.interface";
export const transformPermissions = (permissions: IPermissionManagment | null | undefined) => {
    if (!permissions) return [];
    return Object.entries(permissions)
        .filter(([key]) => key !== "_id" && key !== "admin_object_id" && key !== "createdAt" && key !== "updatedAt" && key !== "__v")
        .map(([key, value]: [string, any]) => ({
            item: key.replace(/_/g, " ").toUpperCase(), // Format key to readable text
            read_access: value.has_read_access,
            write_access: value.has_write_access,
            delete_access: value.has_delete_access,
            all_access: value.has_read_access && value.has_write_access && value.has_delete_access
        }));
};