import React from "react";
import { ROLES } from "../../../constants/roles/Roles";
import { IUser } from "../../../@types/interface/admin.interface";
import { useNavigate } from "react-router-dom";

const useRedirection = () => {
  
    const navigate = useNavigate();

  const handleRedirection = (userResponse: IUser) => {
    switch (userResponse.role) {
      case ROLES.super_admin:
        navigate("/");
        break;
      case ROLES.telecaller:
        navigate(`/admin?cid=${userResponse.builder_object_id}`);
        break;
      case ROLES.project_manager:
        navigate(`/admin?cid=${userResponse.builder_object_id}`);
        break;
      case ROLES.channel_partner:
        navigate(`/admin?cid=${userResponse.builder_object_id}`);
        break;
      case ROLES.builder_admin:
        navigate(`/admin?cid=${userResponse.builder_object_id}`);
        break;
      case ROLES.landlord:
        navigate("/landlord");
        break;
      case ROLES.reseller:
        navigate("/reseller");
        break;
      case ROLES.marketer:
        navigate("/marketer");
        break;
      default:
        console.warn("role:", userResponse.role);
    }
  };
  return {
    handleRedirection
  };
};

export default useRedirection;
