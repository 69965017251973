import React, { useContext, useEffect } from 'react'
import UIContext from '../../../contexts/uiContext/UIContext';
import PermissionContext from '../../../contexts/permissionContext/permissionContext';

const PlotsManagement = () => {
  const {setDashboardHeader} = useContext(UIContext);
const {permissionDetails} = useContext(PermissionContext);
    useEffect(() => {
        setDashboardHeader("Plots Management")
    },[setDashboardHeader])

  return (
    <>
    {!permissionDetails || permissionDetails?.plot_management.has_read_access === true ? 
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70vh' }}>
    <h1>Coming Soon</h1>
  </div> :
  <h1>No access given</h1>
    }
    
    </>
  )
}

export default PlotsManagement