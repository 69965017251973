import { Box } from "@mui/material";
import React from "react";

const Availability = ({
  blockedPlotsCount,
  availablePlotsCount,
  bookedPlotsCount,
}: {
  blockedPlotsCount: number;
  availablePlotsCount: number;
  bookedPlotsCount: number;
}) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "right",
        alignItems: "right",
        marginBottom: "20px",
      }}
    >
      <Box
        sx={{
          borderRadius: "0.5rem",
          justifyContent: "space-between",
          gap: "20px",
          padding: "1rem 2rem",
          border: "0.5px solid #000",
          boxShadow: "0px 0px 3px 2px rgba(0,0,0,0.2)",
        }}
        display={"flex"}
      >
        <div
          style={{
            color: "#606061",
            fontWeight: 900,
            display: "flex",
            alignItems: "center",
            gap: 10,
          }}
        >
          <span style={{ fontSize: "1rem" }}>Blocked Plots:</span>
          <span style={{ fontSize: "1.6rem" }}>{blockedPlotsCount}</span>{" "}
          {/* Yellow color */}
        </div>
        <div
          style={{
            color: "green",
            fontWeight: 900,
            display: "flex",
            alignItems: "center",
            gap: 10,
          }}
        >
          <span style={{ fontSize: "1rem" }}>Available Plots:</span>
          <span style={{ fontSize: "1.6rem" }}>{availablePlotsCount}</span>{" "}
          {/* Yellow color */}
        </div>
        <div
          style={{
            color: "red",
            fontWeight: 900,
            display: "flex",
            alignItems: "center",
            gap: 10,
          }}
        >
          <span> Booked Plots:</span>
          <span style={{ fontSize: "1.2rem", color: "red" }}>
            {bookedPlotsCount}
          </span>{" "}
        </div>
      </Box>
    </div>
  );
};

export default Availability;
