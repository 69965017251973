import { Box, Card, CardContent, Typography } from "@mui/material";
import React from "react";
import { TDashboardCardProps } from "../../../@types/props/Dashboard.props";
import "./dashboardCard.css";

const DashboardCard: React.FC<TDashboardCardProps> = ({
  cardTitle,
  cardValue,
  cardIcon,
}) => {
  return (
    <Card
      sx={{
        padding: "1.5rem",
        width: "270px",
        height: "100px",
        borderRadius: "12px",
        background: "linear-gradient(145deg, #f0f0f3,rgb(226, 226, 226))",
        boxShadow: "4px 4px 10pxrgb(215, 215, 215), -8px -8px 16px #ffffff",
        margin: "0 0.5rem", 
      }}
    >
      <CardContent>
        <div
          className="card-top-section"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "1rem",
          }}
        >
          <Typography
            variant="h6"
            component="h3"
            sx={{
              fontWeight: "600",
              color: "#333",
            }}
          >
            {cardTitle}
          </Typography>
          <div
            className="card-icon"
            style={{
              backgroundColor: "#f4f4f6",
              padding: "0.5rem",
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.1)",
            }}
          >
            {cardIcon}
          </div>
        </div>
        <div
          className="card-bottom-section"
          style={{
            textAlign: "center",
            marginTop: "1rem",
          }}
        >
          <Typography
            variant="h3"
            component="h1"
            sx={{
              fontWeight: "700",
              color: "#555",
              fontSize: "2rem",
            }}
          >
            {cardValue}
          </Typography>
        </div>
      </CardContent>
    </Card>
  );
};

export default DashboardCard;
