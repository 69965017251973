import { BuilderAction } from "../../@types/contexts/BuilderContext/BuilderAction.types";
import { PermissionAction } from "../../@types/contexts/permissionContext/permissionActions.types";
import { Store } from "../../@types/contexts/permissionContext/store.types";
import actions from "./action";

const reducer = (state: Store, action: PermissionAction) => {
	switch (action.type) {
		case actions.SET_PERMISSION: {
			return {
				...state,
				permissionDetails: action.payload.permissionDetails
			};
		}
		default:
			throw new Error("Unexpected action: Permission Context");
	}
};

export default reducer;
