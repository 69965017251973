import { useCallback, useEffect, useState } from "react";
import { IEmployee } from "../../../@types/interface/employee.interface";
import { api } from "../../api";

const useGetEmployeeDetails = (employeeId: string | undefined | null) => {
  const [employeeDetails, setEmployeeDetails] = useState<IEmployee | null>(
    null
  );
  const [loading, setLoading] = useState<boolean>(false);
  const getEmployeeDetails = useCallback(async () => {
    try {
      if (!employeeId) {
        return;
      }
      setLoading(true);
      const filter = {
        employee_id: employeeId,
      };
      const response = await api.admin.getEmployeeDetails(filter);
      setEmployeeDetails(response);
    } catch (error) {
      console.log("Error in getting employee details", error);
    } finally {
      setLoading(false);
    }
  }, [employeeId]);

  useEffect(() => {
    getEmployeeDetails();
  }
  , [getEmployeeDetails]);

  return { employeeDetails, loading, getEmployeeDetails };
};

export default useGetEmployeeDetails;
