import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import AuthContext from "../../../../../contexts/authContext/authContext";
import { IEmployee } from "../../../../../@types/interface/employee.interface";

const EmployeePermissionCellRenderer = ({ data }: { data: IEmployee }) => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);

  const handleRouteToEmployeePermissionDetails = () => {
    navigate(`/admin/employee-permission-details?employee_id=${data._id}`);
  };

  return (
    <Button
      variant="outlined"
      className="green-outlined-button"
      onClick={handleRouteToEmployeePermissionDetails}
    >
      See Details
    </Button>
  );
};

export default EmployeePermissionCellRenderer;
