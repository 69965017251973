import { Checkbox } from "@mui/material";
import React from "react";

const CheckboxCellRenderer = ({
  value,
  handleChnage,
  isDisabled,
}: {
  value: any;
  handleChnage: any;
  isDisabled: boolean;
}) => {
  return <Checkbox color="success" checked={value} onChange={handleChnage} disabled={isDisabled} />;
};

export default CheckboxCellRenderer;
