import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App";
import { store } from "./redux/store";
import reportWebVitals from "./reportWebVitals";
import UIContextProvider from "./contexts/uiContext/Provider";
import BuilderContextProvider from "./contexts/builderContext/Provider";
import AuthContextProvider from "./contexts/authContext/Provider";
import "./index.css"
import PermissionContextProvider from "./contexts/permissionContext/Provider";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <UIContextProvider>
        <BuilderContextProvider>
          <AuthContextProvider>
            <PermissionContextProvider>
            <App />
            </PermissionContextProvider>
          
          </AuthContextProvider>
        </BuilderContextProvider>
      </UIContextProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
