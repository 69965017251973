import React, { ReactNode, useContext, useEffect, useState } from "react";
import { ProjectLeadsColDefs } from "./projectLeadColDefs/projectLeadsColDefs";
import UIContext from "../../../../../contexts/uiContext/UIContext";
import DataGrid from "../../../../shared/dataGrid/DataGrid";
import { enquiryColumnDef } from "../../../../../constants/enquiry/EnquiryColDef";
import ProjectAutoCompleteSearchField from "../../../../shared/projectAutoCompleteSearchField/ProjectAutoCompleteSearchField";
import { IProject } from "../../../../../@types/interface/Projects";
import useQuerySearch from "../../../../hooks/querySearch/useQuerySearch";
import { IassignedProject } from "../../../../../@types/interface/assignedProjectList";
import useGetProjectWiseLead from "../../../../../utils/hooks/getProjectwiseLead/useGetProjectWiseLead";
import { IPagination } from "../../../../../@types/interface/pagination";
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import useGetAllProjectName from "../../../../../utils/hooks/useGetAllProjectName/useGetAllProjectName";
import PermissionContext from "../../../../../contexts/permissionContext/permissionContext";
import NoAccess from "../../../../shared/noAccess/NoAccess";

const ProjectLeads = () => {
  const { setDashboardHeader } = useContext(UIContext);
  const [filters, setFilters] = useState({});
  // const [selectedprojectObjectId, setSelectedProjectObjectId] = useState<string>("6717729e1f62bce244a97655");
  const [pagination, setPagination] = useState<IPagination>({
    currentPage: 1,
    pageCount: 1,
  });
  const {permissionDetails} = useContext(PermissionContext);
  const { allProjectList } = useGetAllProjectName();
  console.log("---response", allProjectList)
  const [selectedProjectId, setSelectedProjectId] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const { rowData } = useGetProjectWiseLead(
    filters,
    pagination,
    selectedProjectId,
    setPagination
  );

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPagination((prev) => ({
      ...prev,
      currentPage: value,
    }));
  };


    const handleProjectChange = (
      event: SelectChangeEvent<string>,
      child: ReactNode 
    ) => {
      setSelectedProjectId(event.target.value as string);
     
    };

  useEffect(() => {
    setDashboardHeader("Project Leads");
  }, [setDashboardHeader]);
  return (
    <>
    {!permissionDetails || permissionDetails?.project_wise_leads.has_read_access === true ? 
     <div>
     <div style={{display: 'flex', gap: '20px'}}>
       <div style={{display: 'flex', flexDirection: 'column'}}>
       <h3>Select Project Name</h3>
     <FormControl sx={{width: "500px"}} variant="outlined">
               <InputLabel id="project-select-label">
                 Select Project
               </InputLabel>
               <Select
                 labelId="project-select-label"
                 id="project-select"
                 value={selectedProjectId}
                 onChange={handleProjectChange}
                 label="Select Project"
               >
                 {allProjectList
                   ? allProjectList.map((project) => (
                       <MenuItem key={project._id} value={project._id}>
                         {project.project_name}
                       </MenuItem>
                     ))
                   : null}
               </Select>
             </FormControl>
       </div>
 
                     <div style={{display: "flex", flexDirection: "column"}}>
                     <h3>Select Time</h3>
     <FormControl sx={{width: "400px"}} variant="outlined">
               <InputLabel id="project-select-label">
                 Select time
               </InputLabel>
               <Select
                 labelId="time-select-label"
                 id="time-select"
                 value={selectedDate}
                 
                 label="Select Time"
               >
                 <MenuItem value="last_week">Last Week</MenuItem>
                 <MenuItem value="last_month">Last Month</MenuItem>
                 <MenuItem value="last_6_months">Last 6 Months</MenuItem>
               </Select>
             </FormControl>
                     </div>
        
     </div>
   
     <div style={{ marginBottom: "30px" }}>
       {/* <ProjectAutoCompleteSearchField setProjectId={handleProjectSelect} /> */}
     </div>

     <p style={{ fontWeight: "500", marginBottom: "25px" }}>
       Project Wise Leads Details
     </p>
     <DataGrid rowData={rowData} colDefs={enquiryColumnDef} />
   </div> : 
  <NoAccess/>
    }
   
    </>
  );
};

export default ProjectLeads;
