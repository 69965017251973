import React, { useContext, useEffect } from "react";
import UIContext from "../../../../contexts/uiContext/UIContext";
import PermissionContext from "../../../../contexts/permissionContext/permissionContext";
import NoAccess from "../../../shared/noAccess/NoAccess";

const Agents = () => {
  const { setDashboardHeader } = useContext(UIContext);
  const { permissionDetails } = useContext(PermissionContext);
  useEffect(() => {
    setDashboardHeader("Agents Details");
  }, [setDashboardHeader]);
  return (
    <>
      { !permissionDetails || permissionDetails?.agents.has_read_access ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "70vh",
          }}
        >
          <h1>Coming Soon</h1>
        </div>
      ) : (
        <NoAccess />
      )}
    </>
  );
};

export default Agents;
