import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import UIContext from "../../../../contexts/uiContext/UIContext";
import SendIcon from "@mui/icons-material/Send";
import BuilderContext from "../../../../contexts/builderContext/BuilderContext";
import AuthContext from "../../../../contexts/authContext/authContext";
import { api } from "../../../../utils/api";
import { useLocation } from "react-router-dom";
import { ROLES } from "../../../../constants/roles/Roles";
import DownloadButton from "../../../shared/downloadbtn/Download";
import { AllIndianStates } from "../../../../constants/allIndianStateList/AllIndianStates";
import DataGrid from "../../../shared/dataGrid/DataGrid";
import { PlotsDefs } from "../../../../constants/plots/plotsDefs";
import BasicPagination from "../../../shared/basicPagination/BasicPagination";
import { IPagination } from "../../../../@types/interface/pagination";
import PermissionContext from "../../../../contexts/permissionContext/permissionContext";
import NoAccess from "../../../shared/noAccess/NoAccess";

const StateReport = () => {
  const { setDashboardHeader } = useContext(UIContext);
  const { builderDetails } = useContext(BuilderContext);
  const { permissionDetails } = useContext(PermissionContext);
  const { user } = useContext(AuthContext);
  const [selectedState, setSelectedState] = useState<string>("");
  const [alternativeReportName, setAlternativeReportName] =
    useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [reportData, setReportData] = useState<any>([]);
  const [pagination, setPagination] = useState<IPagination>({
    currentPage: 0,
    pageCount: 1,
  });

  const handleStateChange = (
    event: SelectChangeEvent<string>,
    child: ReactNode
  ) => {
    setSelectedState(event.target.value as string);
  };
  const handleNameChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setAlternativeReportName(event.target.value as string);
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPagination((prev) => ({
      ...prev,
      currentPage: value,
    }));
  };

  const location = useLocation();

  const isOnAdminPage = location.pathname.startsWith("/admin");
  const isOnOwnerPage = location.pathname.startsWith("/landlord");
  const isOnRetailerPage = location.pathname.startsWith("/reseller");
  const isOnMarketerPage = location.pathname.startsWith("/marketer");

  // const handleNavigateToAddPlots = () => {
  //   navigate(`/admin/add-plots?cid=${builderDetails?._id}`);
  // };
  const getRole = () => {
    if (isOnAdminPage) {
      return ROLES.builder_admin;
    } else if (isOnOwnerPage) {
      return ROLES.landlord;
    } else if (isOnRetailerPage) {
      return ROLES.reseller;
    } else if (isOnMarketerPage) {
      return ROLES.marketer;
    }
  };

  const getAssignedProjectsReport = useCallback(async () => {
    try {
      let response: any = {};

      const filter = {
        role: getRole(),
        search_id: builderDetails ? builderDetails._id : user?._id,
        state: selectedState,
      };
      response = await api.project.getAdminPlots(filter);

      if (response) {
        setReportData(response.result);
        setPagination(response.pagination);
        return response;
      }
    } catch (error) {
      console.error("Error while fetching data:", error);
    }
  }, [builderDetails, getRole, user?._id]);

  useEffect(() => {
    getAssignedProjectsReport();
  }, [getAssignedProjectsReport]);
  useEffect(() => {
    setDashboardHeader("State Wise Report");
  }, [setDashboardHeader]);
  return (
    <>
      {/* {!permissionDetails || permissionDetails?.state_wise_report === true ?  */}
      <>
        <div>
          <h3 style={{ marginBottom: "30px" }}>State Wise Report Generation</h3>
          <div className="button-container" style={{ marginTop: "20px" }}>
            <DownloadButton
              apiCall={getAssignedProjectsReport}
              fileName={`${
                alternativeReportName ? alternativeReportName : selectedState
              }State Report`}
              button_name="Download Report"
              key={"download_projects"}
            />
          </div>
          <Accordion
            defaultExpanded
            sx={{ marginTop: "20px", padding: "20px" }}
          >
            <AccordionDetails>
              <div className="input-field-container">
                <label>State Name:</label>
                <FormControl sx={{ width: "600px" }} variant="outlined">
                  <InputLabel id="state-select-label">Select State</InputLabel>
                  <Select
                    labelId="state-select-label"
                    id="state-select"
                    value={selectedState}
                    onChange={handleStateChange}
                    label="Select State"
                  >
                    {AllIndianStates.map((state) => (
                      <MenuItem key={state.value} value={state.value}>
                        {state.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

              <div className="input-field-container">
                <label>Alternative Report Name:</label>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  onChange={handleNameChange}
                  sx={{ width: "600px", marginBottom: "20px" }}
                />
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
        <div className="button-container" style={{ marginTop: "20px" }}></div>

        {selectedState && (
          <>
            <h3>State Wise Report Details</h3>
            <DataGrid rowData={reportData} colDefs={PlotsDefs} />
            <BasicPagination
              currentPage={pagination.currentPage}
              handlePageChange={handlePageChange}
              pageCount={pagination.pageCount}
            />
          </>
        )}
      </>{" "}
      :
      {/* <NoAccess/>
    } */}
    </>
  );
};

export default StateReport;
