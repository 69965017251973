import UploadLayout from "../admin/plots/addPlots/UploadLayout";
import useGetProjectDetails from "../../hooks/getProjectDetails/useGetProjectDetails";
import useQuerySearch from "../../hooks/querySearch/useQuerySearch";
import "./ProjectLayout.css";
import ToolBox from "./toolBox/ToolBox";
import { useCallback, useEffect, useState } from "react";
import { ILayout } from "../../../@types/interface/layout.interface";

const ProjectLayout = () => {
  const projectId = useQuerySearch("pid");
  const { layoutList, project, setLayoutList } =
    useGetProjectDetails(projectId);
  const [isPolygonPlot,setIsPloygonPlot] =useState<boolean>(false);
  const [selectedPositions, setSelectedPositions] = useState<ILayout[]>([]);
  const [selectedPaths, setSelectedPaths] = useState<string[]>([]);
  const [points, setPoints] = useState<string[]>([]);
  const [isAddPlot, setIsAddPlot] = useState<boolean>(false);
  const [newlistedPlot, setNewlistedPlot] = useState<
    (ILayout | { assigned_to: string | null })[]
  >([]);

  let newPathData = points.length > 0 ? `M ${points.join(" L ")} Z` : "";

  const handleRemove = () => {
    if (selectedPaths.length > 0) {
      console.log("Delete or Backspace pressed");
      const newLayoutList = layoutList.filter(
        (layout) => layout._id && !selectedPaths.includes(layout._id)
      );
      setLayoutList(newLayoutList);
      setSelectedPaths([]);
    }
  };

  const finishPath = () => {
    if (!projectId) return;
    setIsAddPlot(false);
    setPoints([]);
    const newPlotData: ILayout | { assigned_to: string | null } = {
      path: newPathData,
      is_blocked: false,
      is_booked: false,
      facing: "",
      plot_number: 0,
      plot_size: 0,
      price: 0,
      assigned_user_role: "",
      assigned_to: null,
      is_corner_plot: false,
      is_road_facing: false,
      has_bank_loan_available: false,
      has_emi_option: false,
      block_number: "",
      phase_number: "",
      land_type: "",
      area_type: "",
      project_object_id: projectId,
      saved: "",
      dimension: "",
      land_size: 0,
      villa_size: 0,
      farmhouse_size: 0,
      ground_floor_size: 0,
      first_floor_size: 0,
      second_floor_size: 0,
      no_of_trees: 0,
    };

    setNewlistedPlot((listedPlot) => [...listedPlot, newPlotData]); // Functionally update the SVG path
  };

  const handleAddPlotToogle = () => {
    setPoints([]);
    if (!projectId) return;
    if (isAddPlot) {
      finishPath();
    }
    setIsAddPlot((prev) => !prev);
  };

  const handlePolygonAdd = () =>{
    setIsPloygonPlot(true);
    handleAddPlotToogle();
  }

  const handleBoxAdd = () =>{
    setIsPloygonPlot(false);
    handleAddPlotToogle();
  }

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Delete" || event.key === "Backspace") {
        handleRemove();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleRemove]);

  return (
    <div className="project-layout">
      <div className="project-layout-container">
        <UploadLayout
          project={project}
          layoutList={layoutList}
          projectId={projectId || ""}
          setLayoutList={setLayoutList}
          selectedPositions={selectedPositions}
          setSelectedPositions={setSelectedPositions}
          selectedPaths={selectedPaths}
          setSelectedPaths={setSelectedPaths}
          isAddPlot={isAddPlot}
          points={points}
          setPoints={setPoints}
          handleAddPlotToogle={handleAddPlotToogle}
          finishPath={finishPath}
          isPolygonPlot={isPolygonPlot}
          newPathData={newPathData}
          newlistedPlot={newlistedPlot}
          setNewlistedPlot={setNewlistedPlot}
          key={0}
        />
      </div>
      <ToolBox
        handleRemove={handleRemove}
        finishPath={finishPath}
        handleAddPlotBox={handleBoxAdd}
        handleAddPlotPolygon={handlePolygonAdd}
      />
    </div>
  );
};

export default ProjectLayout;
