import { RouteType } from "./config";

//* Imported components for respective routes
import AdminPage from "../components/pages/admin/AdminPage";
import AdminPlots from "../components/pages/admin/plots/Plots";
import EmployeeManagemnet from "../components/pages/admin/employeeManagment/EmployeeDetails";
import AddEmployee from "../components/pages/admin/employeeManagment/addEmployee/AddEmployee";
import AddChangeReq from "../components/pages/projects/projectChangeReq/AddChangeReq";
import ForwardedEnquiry from "../components/pages/admin/forwardedEnquiry/ForwardedEnquiry";
import AssignedProject from "../components/pages/admin/assignedProject/AssignedProject";
import EnquiryDetails from "../components/pages/admin/enquiry/enquiryDetails/EnquiryDetails";
import AddFollowUp from "../components/pages/admin/enquiry/enquiryDetails/followUpGrid/addFollowUp/AddFollowUp";
import FollowUpCalendar from "../components/pages/admin/followUpCalender/FollowUpCalender";
import Reports from "../components/pages/reports/Reports";
import AddReport from "../components/pages/reports/addReport/ReportForm";
import LeadStatusDetails from "../constants/enquiry/leadStatus/leadStatusDetails/LeadStatusDetails";
import Viewlayout from "../components/pages/admin/viewLayout/Viewlayout";
import EnquiryIframeDetails from "../components/pages/admin/enquiryIframe/enquiryIframeDetails/EnquiryIframeDetails";
import Dashboard from "../components/pages/admin/buildersDashboard/BuildersDashboard";
import DashboardIndex from "../components/pages/bookings/DashboardIndex";
import CityReport from "../components/pages/reports/stateReport/StateReport";
import ProjectReport from "../components/pages/reports/projectReport/ProjectReport";
import BuildersDashboard from "../components/pages/admin/buildersDashboard/BuildersDashboard";
import Agents from "../components/pages/admin/agents/Agents";
import { Payments } from "@mui/icons-material";

//? Imported icons
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import MapIcon from "@mui/icons-material/Map";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import LiveHelpOutlinedIcon from "@mui/icons-material/LiveHelpOutlined";
import Book from "@mui/icons-material/Book";
import PieChartIcon from "@mui/icons-material/PieChart";
import People from "@mui/icons-material/People";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmployeeReport from "../components/pages/reports/employeeReport/EmployeeReport";
import PhaseReport from "../components/pages/reports/phaseReport/PhaseReport";
import SiteVisitReport from "../components/pages/reports/siteVisitReport/SiteVisitReport";
import LeadsReport from "../components/pages/reports/leadsReport/LeadsReport";
import PlotsManagement from "../components/pages/plotsManagement/PlotsManagement";
import IncornersLeads from "../components/pages/admin/leads/incornersLeads/IncornersLeads";
import WebsiteLeads from "../components/pages/admin/leads/websiteLeads/WebsiteLeads";
import ProjectLeads from "../components/pages/admin/leads/projectLeads/ProjectLeads";
import LatestLeads from "../components/pages/admin/leads/latestLeads/LatestLeads";
import ChannelPartnerLeads from "../components/pages/admin/leads/channelPartnerLeads/ChannelPartnerLeads";
import Leads from "../components/pages/admin/leads/Leads";
import StateReport from "../components/pages/reports/stateReport/StateReport";

import PaymentPage from "../components/pages/admin/payments/Payments";


import ProjectChangeReq from "../components/pages/projects/projectChangeReq/ProjectChangeReq";
import ProjectSharedDetails from "../components/pages/projects/projectSharedDetails/ProjectSharedDetails";
import ProjectManagement from "../components/pages/projects/projectManagement/ProjectManagement";
import Projects from "../components/pages/projects/Projects";
import EmployeePermissionDetails from "../components/pages/admin/employeeManagment/employeePermissionDetails/EmployeePermissionDetails";


const adminRoutes: RouteType[] = [
  {
    path: "/admin",
    element: <AdminPage />,
    state: "admin",
  },
  {
    path: "/admin/dashboard",
    element: <BuildersDashboard />,
    state: "dashboard",
    sidebarProps: {
      displayText: "Dashboard",
      icon: <PieChartIcon />,
    },
  },

  {
    path: "/admin/projects",
    element: <Projects />,
    state: "projects",
    sidebarProps: {
      displayText: "Projects",
      icon: <MapIcon />,
    },

    child: [
      {
        index: true,
        element: <DashboardIndex />,
        state: "projects.index",
      },

      {
        path: "/admin/projects/change-req",
        element: <ProjectChangeReq />,
        state: "projects.projectChangeReq",
        sidebarProps: {
          displayText: "Project Change Request",
        },
      },

      {
        path: "/admin/projects/shared-details",
        element: <AdminPlots />,
        state: "projects.projectSharedDetails",
        sidebarProps: {
          displayText: "Project Shared Details",
        },
      },
      {
        path: "/admin/projects/management",
        element: <ProjectManagement />,
        state: "projects.projectManagement",
        sidebarProps: {
          displayText: "Project Management",
        },
      },
    ],
  },

  {
    path: "/admin/employee",
    element: <EmployeeManagemnet />,
    state: "employee",
    sidebarProps: {
      displayText: "Employees",
      icon: <PeopleOutlinedIcon />,
    },
  },
{
  path: "/admin/employee-permission-details",
  element: <EmployeePermissionDetails />,
  state: "employeeManagment",
},
  {
    path: "/admin/plots-management",
    element: <PlotsManagement />,
    state: "plotsManagement",
    sidebarProps: {
      displayText: "Plots Management",
      icon: <LocationOnIcon />,
    },
  },
  {
    path: "/admin/leads",
    element: <Leads />,
    state: "leads",
    sidebarProps: {
      displayText: "Leads",
      icon: <LiveHelpOutlinedIcon />,
    },

    child: [
      {
        index: true,
        element: <DashboardIndex />,
        state: "leads.index",
      },

      {
        path: "/admin/leads/incorners-leads",
        element: <IncornersLeads />,
        state: "leads.incornersLeads",
        sidebarProps: {
          displayText: "Incorners Leads",
        },
      },

      {
        path: "/admin/leads/website-leads",
        element: <WebsiteLeads />,
        state: "leads.websiteLeads",
        sidebarProps: {
          displayText: "Website Leads",
        },
      },

      {
        path: "/admin/leads/project-wise-leads",
        element: <ProjectLeads />,
        state: "leads.projectLeads",
        sidebarProps: {
          displayText: "Project Wise Leads",
        },
      },
      {
        path: "/admin/leads/latest-leads",
        element: <LatestLeads />,
        state: "leads.latestLeads",
        sidebarProps: {
          displayText: "Latest Leads",
        },
      },
      {
        path: "/admin/leads/channel-partner-leads",
        element: <ChannelPartnerLeads />,
        state: "leads.channelPartnerLeads",
        sidebarProps: {
          displayText: "Channel Partner Leads",
        },
      },
    ],
  },

  {
    path: "/admin/enquiryiframe-details",
    element: <EnquiryIframeDetails mode="ENQUIRY" />,
    state: "enquiryIframe",
  },
  {
    path: "/admin/project-details",
    element: <Viewlayout />,
    state: "plots",
  },
  {
    path: "/admin/lead-status-details",
    element: <LeadStatusDetails />,
    state: "enquiry",
  },
  {
    path: "/admin/enquiry-details",
    element: <EnquiryDetails mode="ENQUIRY" />,
    state: "plots",
  },
  {
    path: "/admin/forwarded-enquiry-details",
    element: <EnquiryDetails mode="FORWARDED-ENQUIRY" />,
    state: "plots",
  },
  {
    path: "/admin/add-follow-up",
    element: <AddFollowUp />,
    state: "followup",
  },

  {
    path: "/admin/forwarded_enquiry",
    element: <ForwardedEnquiry />,
    state: "forwarded_enqury",
    sidebarProps: {
      displayText: "Forwared Enquiry",
      icon: <ArrowForwardOutlinedIcon />,
    },
  },
  {
    path: "/admin/assigned_project",
    element: <AssignedProject />,
    state: "assigned_projects",
    sidebarProps: {
      displayText: "Assigned Projects",
      icon: <AssignmentTurnedInOutlinedIcon />,
    },
  },

  {
    path: "/admin/reports",
    element: <Reports />,
    state: "reports",

    sidebarProps: {
      displayText: "Reports",
      icon: <Book />,
    },
    child: [
      {
        index: true,
        element: <DashboardIndex />,
        state: "reports.index",
      },
      {
        path: "/admin/reports/state-wise-report",
        element: <StateReport />,
        state: "reports.cityReport",
        sidebarProps: {
          displayText: "State Wise Report",
        },
      },

      {
        path: "/admin/reports/project-wise-report",
        element: <ProjectReport />,
        state: "reports.projectReport",
        sidebarProps: {
          displayText: "Project Wise Report",
        },
      },
      {
        path: "/admin/reports/leads-report",
        element: <LeadsReport />,
        state: "reports.leadsReport",
        sidebarProps: {
          displayText: "Leads Report",
        },
      },
      {
        path: "/admin/reports/employee-report",
        element: <EmployeeReport />,
        state: "reports.employeeReport",
        sidebarProps: {
          displayText: "Employee Report",
        },
      },
      {
        path: "/admin/reports/phase-wise-report",
        element: <PhaseReport />,
        state: "reports.phaseReport",
        sidebarProps: {
          displayText: "Phase Report",
        },
      },
      {
        path: "/admin/reports/site-visit-report",
        element: <SiteVisitReport />,
        state: "reports.siteVisitReport",
        sidebarProps: {
          displayText: "Site Visit Report",
        },
      },
    ],
  },

  {
    path: "/admin/add-employee",
    element: <AddEmployee mode="ADD" key={"builder_employee"} />,
    state: "admin",
  },

  {
    path: "/admin/add-request",
    element: <AddChangeReq />,
    state: "admin",
  },

  {
    path: "/admin/payments",
    element: <PaymentPage/>,
    state: "payments",
    sidebarProps: {
      displayText: "Payments",
      icon: <CurrencyRupeeIcon />,
    },
  },
  {
    path: "/admin/agents",
    element: <Agents />,
    state: "agents",
    sidebarProps: {
      displayText: "Agents",
      icon: <PermContactCalendarIcon />,
    },
  },
];

export default adminRoutes;
