import React from "react";
import { IEnquiryIframe } from "../../../../../../@types/interface/enquiryIframe.interface";
import { IEmployee } from "../../../../../../@types/interface/employee.interface";

const FormattedDate = ({ data }: { data: IEmployee }) => {
    return (
        <div>
            <span> {data.createdAt ? new Date(data.createdAt).toLocaleString() : "N/A"}</span>
        </div>
    );
};

export default FormattedDate;
