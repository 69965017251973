import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  TextField,
} from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";
import UIContext from "../../../../contexts/uiContext/UIContext";
import SendIcon from "@mui/icons-material/Send";
import DownloadButton from "../../../shared/downloadbtn/Download";
import BuilderContext from "../../../../contexts/builderContext/BuilderContext";
import { api } from "../../../../utils/api";
import PermissionContext from "../../../../contexts/permissionContext/permissionContext";
import NoAccess from "../../../shared/noAccess/NoAccess";

const EmployeeReport = () => {
  const { setDashboardHeader } = useContext(UIContext);
  const { builderDetails } = useContext(BuilderContext);
  const [alternativeReportName, setAlternativeReportName] =
    useState<string>("");
  const [employeeData, setEmployeeData] = useState([]);
  const [isClicked, setIsClicked] = useState<boolean>(false);
  const { permissionDetails } = useContext(PermissionContext);
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setAlternativeReportName(event.target.value as string);
  };

  const getFullEmployeeList = useCallback(async () => {
    try {
      const filter = {
        page: 1,
        sortField: "updatedAt",
        builder_object_id: builderDetails?._id || "",
      };
      const response = await api.admin.getEmployeeList(filter);
      if (response) {
        setEmployeeData(response.result);
      }
    } catch (error) {
      console.log("error", error);
    }
  }, [builderDetails?._id]);

  const DownloadEmployeeReport = useCallback(async () => {
    try {
      const filter = {
        page: 1,
        sortField: "updatedAt",
        builder_object_id: builderDetails?._id || "",
      };
      const response = await api.admin.getEmployeeList(filter);
      if (response) {
        return response;
      }
    } catch (error) {
      console.log("error", error);
    }
  }, [builderDetails?._id]);
  useEffect(() => {
    getFullEmployeeList();
  }, [getFullEmployeeList]);
  useEffect(() => {
    setDashboardHeader("Employee Report");
  }, [setDashboardHeader]);
  return (
    <>
      {!permissionDetails ||
      permissionDetails?.employee_report?.has_read_access ? (
        <>
          <div>
            <h3>Employee Report Generation</h3>
            <div className="button-container" style={{ marginTop: "20px" }}>
              <DownloadButton
                apiCall={DownloadEmployeeReport}
                fileName={`${
                  alternativeReportName ? alternativeReportName : "Employee"
                } Report`}
                button_name="Download Employee Report"
                key={"download_projects"}
              />
            </div>
            <Accordion defaultExpanded sx={{ marginTop: "20px" }}>
              <AccordionDetails>
                <div className="input-field-container">
                  <label>Alternative Report Name:</label>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    sx={{ width: "600px", marginBottom: "20px" }}
                    onChange={handleChange}
                  />
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "green",
              textTransform: "capitalize",
              marginTop: "20px",
              marginBottom: "20px",
              "&:hover": {
                backgroundColor: "darkgreen",
              },
            }}
            onClick={() => {
              setIsClicked(true);
              getFullEmployeeList();
            }}
          >
            Fetch Employee Report Details
          </Button>
          {isClicked && (
            <>
              <h3>Employee Report Details</h3>
              {/* <DataGrid rowData={employeeData} colDefs={EmployeeColDefs}/> */}
            </>
          )}
        </>
      ) : (
        <NoAccess />
      )}
    </>
  );
};

export default EmployeeReport;
